import React from 'react';
import apiFetch from '../../src/js/fetch';
import ContentContainer from '../ContentContainer';
import editIcon from '../../images/icons/ic-edit.svg';
import closeIcon from '../../images/icons/ic-exit.svg';
import SegmentType from '../SegmentType';
import coordinates from '../../images/icons/ic-coordinates.svg';
import elevation from '../../images/icons/ic-elevation.svg';
import BarLoader from 'react-spinners/BarLoader';
import BrowseSegments from '../Segments/Browse';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import focusLock from 'dom-focus-lock';
import moment from 'moment';
import { addTippys } from '../../src/js/tooltips';
import infoIcon from '../../images/icons/ic-info.svg';

class CreateContest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // form
      name: props.name || '',
      description: props.description || '',
      from: props.from || '',
      to: props.to || '',
      access: props.access || 'public',
      challengeType: props.challenge_type || 'segment',
      distanceChallengeTypes: props.distance_challenge_types || [],
      distanceChallengeType: props.distance_challenge_types.length
        ? props.distance_challenge_types[0].id
        : '',

      loading: false,
      segments: props.segments || [],
      destroySegments: [],
      addNewSegments: [],

      showSegmentFinder: false,
    };

    this.minFromDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    this.minToDate = moment().format('YYYY-MM-DD');

    this.segmentFinderRef = React.createRef();
    this.focusLockRef = React.createRef();
  }

  componentDidMount() {
    addTippys();
  }

  componentWillUnmount() {
    console.log('unmounting');

    if (this.focusLock) {
      focusLock.off(this.focusLockRef.current);
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { name, from, to, access, description, segments, challengeType } =
      this.state;
    const { edit, contest_id } = this.props;

    if (
      !name ||
      !description ||
      !from ||
      !to ||
      !access ||
      !challengeType ||
      (challengeType == 'segment' && !segments.length)
    ) {
      console.log('Missing data:', {
        name,
        description,
        from,
        to,
        access,
        segments,
      });
      this.setState({
        loading: false,
        error:
          'You need to fill in all fields, and select at least one segment.',
      });
      return;
    }

    this.setState({ loading: true, error: null });

    // console.log('Submit form:', this.state.destroySegments);
    const body = {
      // :name, :from, :to, :public, :description, contest_segments_attributes: [ :segment_id ]
      contest: {
        name: name,
        to: to,
        public: challengeType == 'segment' ? access : 'private', // If not segment challenge, always private
        description: description,
        challenge_type: challengeType,
      },
    };

    if (challengeType == 'distance') {
      // If the challenge type is distance, we need to add the distance challenge type to the contest.
      body.contest.distance_challenge_type_id = parseInt(
        this.state.distanceChallengeType
      );
    } else if (challengeType == 'segment') {
      // If the challenge type is segment, we need to add the segments to the contest_segments_attributes array.
      body.contest.contest_segments_attributes = [
        ...segments
          .filter((s) => !s.contest_id)
          .map((s) => ({ segment_id: s.id })),
        ...this.state.destroySegments.map((s) => ({ id: s, _destroy: true })),
      ];
    }

    if (!edit) {
      // Don't allow changing the from date on an existing contest
      body.contest.from = from;
    }

    console.log({ body });

    try {
      const data = await apiFetch(
        `/api/challenges${edit ? `/${contest_id}` : ''}`,
        {
          method: edit ? 'PUT' : 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify(body),
        }
      );

      window.location.href = data.redirect_to;
    } catch (e) {
      console.log({ e });
      const err = await e.json();
      console.log({ err });
      this.setState({
        loading: false,
        error:
          err.error_message || 'Something went wrong. Please try again later.',
      });
    }
  };

  handleChange = (event) => {
    if (event.target.name === 'from') {
      // If the from date changes, reset the to date.
      this.minToDate = moment(event.target.value)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      if (
        moment(event.target.value).isAfter(this.state.to) ||
        moment(event.target.value).isSame(this.state.to)
      ) {
        this.setState({ to: '' });
      }
    }
    this.setState({
      [event.target.name]: event.target.value,
      error: null,
    });
  };

  onAddSegmentToAddList = (segment) => {
    const exists = this.state.addNewSegments.find((s) => s.id === segment.id);
    if (exists) {
      this.setState({
        addNewSegments: this.state.addNewSegments.filter(
          (s) => s.id !== segment.id
        ),
      });
    } else {
      this.setState({
        addNewSegments: [...this.state.addNewSegments, segment],
      });
    }
  };

  onAddSegmentsToList = () => {
    const updated = [...this.state.segments];
    for (const segment of this.state.addNewSegments) {
      const exists = updated.find((s) => s.id === segment.id);
      if (!exists) {
        // Only add the segment if it's not already in the list
        updated.push(segment);
      }
    }

    this.setState({
      error: null,
      segments: updated,
      addNewSegments: [],
      showSegmentFinder: false,
    });

    // Enable scrolling on the body
    clearAllBodyScrollLocks();
  };

  onAddSegment = (segment) => {
    const { edit } = this.props;
    console.log({ segment, edit });

    /**
     * When edit=true the existing segments are objects with Segment data, and a contest_segment_id.
     * When edit=false segments are just Segment data.
     */
    const exists = this.state.segments.find((s) => s.id === segment.id);
    if (exists) {
      if (edit && exists.contest_id) {
        const ok = confirm(
          'This segment is already part of the live challenge. Do you really want to remove it? This will remove all data for this segment from the challenge.'
        );
        if (!ok) {
          return;
        }
        // This segment is already part of the contest, so we need to remove it, and add it to the destroySegments array, so it gets deleted when we update the contest.
        this.setState({
          destroySegments: [
            ...this.state.destroySegments,
            exists.contest_segment_id,
          ],
        });
      }

      console.log('Already added');
      this.setState({
        segments: this.state.segments.filter((s) => s.id !== segment.id),
      });
    } else {
      this.setState({
        segments: [...this.state.segments, segment],
      });
    }
  };

  toggleSegmentFinder = async () => {
    const { showSegmentFinder } = this.state;

    const open = !showSegmentFinder;

    if (!open) {
      // Enable scrolling on the body
      clearAllBodyScrollLocks();

      // Reset the added segments
      this.setState({ addNewSegments: [] });
    }

    await this.setState({ showSegmentFinder: open });

    if (open) {
      // Disable scrolling on the body, and lock focus inside the modal
      disableBodyScroll(this.segmentFinderRef.current);
      this.focusLock = focusLock.on(this.focusLockRef.current);
    }
  };

  render() {
    const {
      loading,
      name,
      from,
      to,
      access,
      description,
      segments,
      addNewSegments,
      error,
      showSegmentFinder,
      challengeType,
      distanceChallengeTypes,
      distanceChallengeType,
    } = this.state;
    const { edit } = this.props;

    return (
      <section className='pt-8 pb-20'>
        <form
          id='create-contest-form'
          className=''
          method='post'
          onSubmit={this.handleSubmit}
        >
          <div
            className={`relative w-full lg:max-w-[53rem] mb-8 border-b-2 ${
              error && !name ? 'border-error' : 'border-border'
            }`}
          >
            <textarea
              name='name'
              id='name'
              autoFocus
              className={`placeholder:text-heading-md placeholder:font-heading text-heading-xs leading-[2.2] mb-2 pr-6 align-middle bg-transparent appearance-none max-w-full outline-none border-none h-20 w-full`}
              required={true}
              onChange={this.handleChange}
              value={name}
              placeholder='Challenge title'
            />
            <img
              src={editIcon}
              className='absolute right-0 my-auto top-0 bottom-0 mr-2 cursor-text pointer-events-none w-[1.5rem] h-[1.5rem]'
            />
          </div>

          <div className='mb-16pt'>
            <div className='w-full grid gap-4'>
              <div className='w-full grid xl:grid-cols-2 3xl:grid-cols-3 gap-4 items-start'>
                <div className={`grid gap-4`}>
                  <ContentContainer classes='flex-1 3xl:col-span-2 flex flex-col'>
                    <h2 className='mini-label font-medium'>Select type</h2>

                    {edit && (
                      <p className='mini-text text-text-grey-600'>
                        You can't change type in an existing challenge.
                      </p>
                    )}
                    <div className='flex flex-wrap'>
                      <div
                        data-tippy-content='Compete in designated A to B segments and earn points based on your performance relative to other athletes. Ideal for those who can access specific locations.'
                        className={`flex items-start small-body-text mb-2 mr-6 ${
                          edit ? 'opacity-40' : ''
                        }`}
                      >
                        <input
                          type='radio'
                          name='challengeType'
                          id='segment'
                          value='segment'
                          className='h-auto mr-2 translate-y-1'
                          checked={challengeType === 'segment'}
                          onChange={this.handleChange}
                          disabled={!!edit}
                        />
                        <label
                          htmlFor='segment'
                          className='text-text flex-1 min-w-0 pl-2'
                        >
                          Segment challenge
                        </label>
                      </div>
                      <div
                        className={`flex items-start mb-4 small-body-text ${
                          edit ? 'opacity-40' : ''
                        }`}
                        data-tippy-content='Focus solely on accumulating distance, wherever you are. Your total distance determines your rank on the leaderboard. Perfect for participants in any location.'
                      >
                        <input
                          type='radio'
                          name='challengeType'
                          id='distance'
                          value='distance'
                          className='h-auto mr-2 translate-y-1'
                          checked={challengeType === 'distance'}
                          onChange={this.handleChange}
                          disabled={!!edit}
                        />
                        <label
                          htmlFor='distance'
                          className='text-text flex-1 min-w-0 pl-2'
                        >
                          Distance challenge
                        </label>
                      </div>
                    </div>

                    {challengeType == 'distance' && (
                      <div className='mt-8'>
                        <h3 className='mini-label font-medium'>Select sport</h3>
                        <select
                          className='input'
                          value={distanceChallengeType}
                          onChange={this.handleChange}
                          name='distanceChallengeType'
                          disabled={!!edit}
                        >
                          {distanceChallengeTypes.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      // <div className='mt-8'>
                      //   <h2 className='mini-label font-medium'>Select sport</h2>
                      // </div>
                    )}
                  </ContentContainer>
                  <ContentContainer classes='flex-1 3xl:col-span-2 flex flex-col'>
                    <h2 className='mini-label font-medium'>
                      Describe your challenge
                    </h2>
                    <textarea
                      name='description'
                      id='description'
                      required={true}
                      className='min-h-[10rem] flex-1'
                      placeholder='An epic description.'
                      onChange={this.handleChange}
                      value={description}
                    ></textarea>
                  </ContentContainer>
                </div>
                <div className='grid gap-4'>
                  <ContentContainer>
                    <div className='grid md:grid-cols-2 gap-4'>
                      <div>
                        <h2 className='mini-label font-medium'>From...</h2>
                        <input
                          type='date'
                          disabled={!!edit}
                          name='from'
                          id='from'
                          min={this.minFromDate}
                          className={`input w-full appearance-none ${
                            edit ? 'opacity-40' : ''
                          }`}
                          onChange={this.handleChange}
                          required={true}
                          value={from}
                        />
                        <p className='mini-text text-text-grey-600 mt-4'>
                          If the starting date is in the past, we'll add your
                          completed activities from that date to the
                          leaderboards in the challenge.
                        </p>
                      </div>
                      <div>
                        <h2 className='mini-label font-medium'>To...</h2>
                        <input
                          type='date'
                          name='to'
                          id='to'
                          className={`input w-full appearance-none ${
                            from ? '' : 'opacity-50'
                          }`}
                          onChange={this.handleChange}
                          required={true}
                          min={this.minToDate}
                          value={to}
                          disabled={!from}
                        />
                      </div>
                    </div>
                  </ContentContainer>
                  {challengeType == 'segment' && (
                    <ContentContainer>
                      <h2 className='mini-label font-medium'>
                        Who can access your challenge?
                      </h2>
                      <div className='flex items-start small-body-text mb-2'>
                        <input
                          type='radio'
                          name='access'
                          id='public'
                          value='public'
                          className='h-auto mr-2 translate-y-1'
                          checked={access === 'public'}
                          onChange={this.handleChange}
                        />
                        <label
                          htmlFor='public'
                          className='text-text flex-1 min-w-0 pl-2'
                        >
                          Public (Everyone can see your challenge and join it)
                        </label>
                      </div>
                      <div className='flex items-start mb-4 small-body-text'>
                        <input
                          type='radio'
                          name='access'
                          id='private'
                          value='private'
                          className='h-auto mr-2 translate-y-1'
                          checked={access === 'private'}
                          onChange={this.handleChange}
                        />
                        <label
                          htmlFor='private'
                          className='text-text flex-1 min-w-0 pl-2'
                        >
                          Private (Only people with the link can access your
                          challenge and join it)
                        </label>
                      </div>
                    </ContentContainer>
                  )}
                </div>
              </div>
              {challengeType == 'segment' && (
                <ContentContainer>
                  <h2 className='mini-label font-medium'>
                    Selected segments ({segments.length})
                  </h2>

                  {segments.map((segment, i) => (
                    <div
                      className={`flex items-center mb-2 last:mb-0`}
                      key={`segment-selected-${segment.id}`}
                    >
                      <div className='flex-1 bg-background-quinary p-3 pl-4 rounded border border-border flex justify-between'>
                        <div className='flex items-center pr-4 mr-4 border-r border-border-secondary'>
                          <input
                            type='checkbox'
                            className=''
                            id={`segment-${segment.id}`}
                            checked={true}
                            onChange={() => this.onAddSegment(segment)}
                          />
                        </div>
                        <div className='md:flex flex-1 items-center justify-between small-body-text'>
                          <div className='flex flex-1 flex-wrap'>
                            <span className='font-medium'>{segment.name}</span>
                            {segment.created_by_you && (
                              <span className='mini-label text-text-grey-600 pl-2'>
                                (Created by you)
                              </span>
                            )}
                          </div>
                          <div className="flex items-center md:ml-2 flex-1 mr-4 <%= s.city.present? ? 'mb-2 md:mb-0': '' %>">
                            <SegmentType typeIconUrl={segment.sport_icon_url} />
                            <span className='pl-2 sm:pl-4 pr-2 sm:pr-4'>|</span>
                            <span className='pr-2'>
                              <img src={coordinates} className='w-[14px]' />
                            </span>
                            <span>
                              {(segment.distance_in_meters / 1000).toFixed(2)}
                            </span>
                            <span>km</span>
                            <span className='pl-2 sm:pl-4 pr-2 sm:pr-4'>|</span>
                            <span className='pr-2'>
                              <img src={elevation} className='w-[14px]' />
                            </span>
                            <span>
                              {segment.elevation
                                ? parseFloat(segment.elevation).toFixed(0)
                                : '00'}
                            </span>
                            <span>m</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <button
                    type='button'
                    className='button-text underline mt-4'
                    onClick={this.toggleSegmentFinder}
                  >
                    Add segment(s)
                  </button>
                </ContentContainer>
              )}
            </div>
            {loading ? (
              <div className='my-4'>
                <BarLoader />
              </div>
            ) : null}
          </div>
          <div className='flex items-center flex-wrap'>
            <button type='submit' className='button dark mb-4 mr-6'>
              {edit
                ? 'Confirm and update challenge'
                : 'Confirm and create challenge'}
            </button>
            <a
              href={'/challenges'}
              className='button-text underline mb-4 text-text-grey-600'
            >
              Cancel
            </a>
          </div>
          {error && <p className='text-text-grey-600 max-w-[35rem]'>{error}</p>}
        </form>

        {showSegmentFinder && (
          <div
            role='alertdialog'
            className='fixed top-0 left-0 2xl:left-[15%] bottom-0 right-0 bg-background-secondary bg-opacity-60 z-[1000] flex p-4 md:p-8'
          >
            <div
              ref={this.focusLockRef}
              className='bg-background-quaternary rounded-lg border border-border-darker-yellow mx-auto w-full xl:max-h-full py-4 lg:py-8 flex flex-col relative'
            >
              <div className='flex justify-between items-start px-4 lg:px-8'>
                <h2 className='mini-label font-medium'>Browse segments</h2>
                <button
                  type='button'
                  className='h-auto small-link-text text-text-grey-600'
                  onClick={this.toggleSegmentFinder}
                >
                  Close
                  <img src={closeIcon} className='inline-block ml-2' />
                </button>
              </div>
              <div
                ref={this.segmentFinderRef}
                className='w-full flex-1 overflow-y-auto px-4 lg:px-8'
              >
                <BrowseSegments
                  sectionSize='xl:h-full'
                  addSegmentsToContest={true}
                  onAddSegment={this.onAddSegmentToAddList}
                  onAddSegmentsToList={this.onAddSegmentsToList}
                  newSegments={addNewSegments}
                  existingSegments={segments}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
}
export default CreateContest;
